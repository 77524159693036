import React from "react";
import { useTranslation } from "react-i18next";

function Area() {
  // Translation hook
  const { t } = useTranslation();

  return (
    <div>
      <h2>{t("area.discoverBeauty")}</h2>
      <p>
        <em>{t("area.pristineBeaches")}</em>
      </p>
      <p>
        {t("area.experienceCharm")}
      </p>
      <p>
        {t("area.propertyLocated")}
      </p>
      <p>
        <em>{t("area.turtleSanctuary")}</em>
      </p>
      <p>
        {t("area.areaWellKnown")}
        <a
          href="https://www.google.com/search?q=Cuyutlan&tbm=isch"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("area.quickGoogleSearch")}
        </a>
        {t("area.beautyEntireArea")}
      </p>
      <p>
        <em>{t("area.landApproximately")}</em>
      </p>
    </div>
  );
}

export default Area;
