import React from "react";
import { useTranslation } from "react-i18next";

function Details() {
  const { t } = useTranslation();

  return (
    <div className="details">
      <h3>{t("details.keyDetails")}</h3>
      <ul>
        <li>
          <strong>{t("details.location")}:</strong> {t("details.locationDescription")}
        </li>
        <li>
          <strong>{t("details.accessibility")}:</strong> {t("details.accessibilityDescription")}
        </li>
        <li>
          <strong>{t("details.size")}:</strong> {t("details.sizeDescription")}
        </li>
        <li>
          <strong>{t("details.price")}:</strong> {t("details.priceDescription")}
        </li>
        <li>
          <strong>{t("details.zoning")}:</strong> {t("details.zoningDescription")}
        </li>
        <li>
          <strong>{t("details.development")}:</strong> {t("details.developmentDescription")}
        </li>
      </ul>
    </div>
  );
}

export default Details;
