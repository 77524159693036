import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import image from "../assets/images/img01.jpg";

function AboutSnap({ theme }) {
  const { t } = useTranslation();

  const handleClick = () => {
    // Scroll to the top of the page when the link is clicked
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Link to="/about" onClick={handleClick}>
      <div className="snap-card">
        <div className="snap-text">
          <h2>{t("aboutSnap.title")}</h2>
          <p>{t("aboutSnap.description")}</p>
        </div>
        <img
          src={image}
          alt="Cuyutlan Overview"
          className="snap-image"
        />
      </div>
    </Link>
  );
}

export default AboutSnap;
