import React from "react";
import "./Contact.css";
import Seller from "../../src/components/Seller";
import ContactForm from "../../src/components/ContactForm";
import image from "../assets/images/img01.jpg";  

const Contact = () => {
  const styles = {
    backgroundImage: `url(${image})`,
  };

  return (
    <div>
      <div className="backimg" style={styles}>
        <div className="section">
          <div className="spacing">
            <div className="cardboard opa">
              <Seller />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="spacing">
          <div className="cardboard opa">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
