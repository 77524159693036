import React from "react";
import { useTranslation } from "react-i18next";
import Featured from "../../src/components/Featured";
import AboutSnap from "../components/AboutSnap";
import "./Home.css";

function Home() {
  const { t } = useTranslation();
  const title = t("home.title");
  const theme = "light"; // or "dark"

  console.log('env var ' + process.env.PUBLIC_URL)
  return (
    <div>
      <Featured title={title} />
      <div className="section light">
        <AboutSnap theme={theme} />
      </div>
    </div>
  );
}

export default Home;
