import React from "react";
import Map from "../../src/components/Map";
import Album from "../components/Album";
import Area from "../components/Area";
import Player from "../components/Player";
import Details from "../components/Details";
import Lot from "../components/Lot";
import "./About.css";
import image6 from "../assets/images/img06.jpg";
import image7 from "../assets/images/img07.jpg";

const albumImages = [
  { src: image6, alt: "Image 6" },
  { src: image7, alt: "Image 7" },
];

function About() {
  return (
    <div>
      <div className="section dark">
        <div className="spacing">
          <div className="grid-horizontal">
            <div className="cardboard light">
              <Details />
            </div>
            <Map />
          </div>
        </div>
      </div>
      <div className="section light">
        <div className="spacing">
          <div className="cardboard dark">
            <Player />
          </div>
        </div>
      </div>
      <div className="section dark">
        <div className="spacing">
          <div className="cardboard light">
            <Area />
          </div>
        </div>
      </div>
      <div className="section light">
        <div className="spacing">
          <div className="grid-vertical">
            <div className="cardboard dark">
              <Lot />
            </div>
            <div className="cardboard dark">
              <Album images={albumImages} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
