import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import Nav from "./pages/Nav";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import About from "./pages/About";
import Home from "./pages/Home";
import Footer from "./pages/Footer";

function App() {
  const basename = process.env.NODE_ENV === "development" ? "" : "/";
  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
        <Router basename={basename}>
          <div className="tabs">
            <Nav />
          </div>
          <Routes>
            <Route
              path="/gallery"
              element={
                <div>
                  <Gallery />
                </div>
              }
            />
            <Route
              path="/about"
              element={
                <div>
                  <About />
                </div>
              }
            />
            <Route
              path="/contact"
              element={
                <div>
                  <Contact />
                </div>
              }
            />
            <Route
              path=""
              element={
                <div>
                  <Home />
                </div>
              }
            />
          </Routes>{" "}
        </Router>
        <Footer />
      </div>
    </I18nextProvider>
  );
}

export default App;
