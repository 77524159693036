import React from "react";
import image from "../assets/videos/vid01.mp4";
import { useTranslation } from "react-i18next";

function Player() {
  const { t } = useTranslation();

  const videos = [
    {
      src: image,
      title: "Video 1",
    },
  ];

  return (
    <div>
      <p>
        {t("player.perfectlySituated")} {t("player.investmentOpportunity")}
        {t("player.exploreBeauty")} {t("player.learnMore")}
      </p>
      <video controls autoPlay width="100%">
        <source src={videos[0].src} type="video/mp4" />
        {t("player.videoNotSupported")}
      </video>
    </div>
  );
}

export default Player;
