import React from "react";
import { useForm, ValidationError } from '@formspree/react';
import { useTranslation } from "react-i18next";

function ContactForm() {
  const [state, handleSubmit] = useForm("xpzvayga");
  
  // State to manage form data (your additional fields)
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    message: "",
    subject: "",
  });

  // Translation hook
  const { t } = useTranslation();

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission (email sending logic can be added here)
  const handleSubmitForm = (e) => {
    e.preventDefault();

    // Validation checks
    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
      // Handle validation error, for example, show a message to the user
      console.error("Please fill in all fields.");
      return;
    }

    // Continue with form submission
    handleSubmit(e);
  };

  if (state.succeeded) {
    // You can customize the success message here
    return <p>{t("contactForm.thankYou")}</p>;
  }

  return (
    <div className="contact-form">
      <h2>{t("contactForm.contactUs")}</h2>
      <form onSubmit={handleSubmitForm}>
        <div>
          <label htmlFor="name">{t("contactForm.name")}</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required // Added required attribute
          />
        </div>
        <div>
          <label htmlFor="email">{t("contactForm.email")}</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required // Added required attribute
          />
        </div>
        <div>
          <label htmlFor="subject">{t("contactForm.subject")}</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
            required // Added required attribute
          />
        </div>
        <div>
          <label htmlFor="message">{t("contactForm.message")}</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required // Added required attribute
          />
        </div>
        {/* Error handling for individual fields */}
        <ValidationError 
          prefix={t("contactForm.name")} 
          field="name"
          errors={state.errors}
        />
        <ValidationError 
          prefix={t("contactForm.email")} 
          field="email"
          errors={state.errors}
        />
        <ValidationError 
          prefix={t("contactForm.subject")} 
          field="subject"
          errors={state.errors}
        />
        <ValidationError 
          prefix={t("contactForm.message")} 
          field="message"
          errors={state.errors}
        />
        <button type="submit" disabled={state.submitting}>
          {t("contactForm.submit")}
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
