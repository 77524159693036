import React from "react";
import { useTranslation } from "react-i18next";
import "./Footer.css";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-section contact-info">
          <h3>{t("footer.contactInformation")}</h3>
          <p>
            <strong>{t("footer.contact")}:</strong> Adam Diamond
          </p>
          <p>
            <strong>{t("footer.email")}:</strong>{" "}
            <a href="mailto:adam@chartwellmanhattan.com">
              adam@chartwellmanhattan.com
            </a>
          </p>
          <p>
            <strong>{t("footer.phone")}:</strong>{" "}
            <a href="tel:+19178078169">+1(917)807-8169</a>
          </p>
        </div>
        <div className="footer-section site-links">
          <h3>{t("footer.quickLinks")}</h3>
          <ul>
            <li>
              <a href="/">{t("nav.home")}</a>
            </li>
            <li>
              <a href="/about">{t("nav.about")}</a>
            </li>
            <li>
              <a href="/gallery">{t("nav.gallery")}</a>
            </li>
            <li>
              <a href="/contact">{t("nav.contact")}</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2023 Adam Diamond. {t("footer.allRightsReserved")}</p>
      </div>
    </footer>
  );
}

export default Footer;
