import React from "react";
import image from "../assets/images/img09.jpg";
import { useTranslation } from "react-i18next";

function Featured({  title }) {
  const { t } = useTranslation();

  const styles = {
    backgroundImage: `url(${image})`,
  };

  return (
    <div className="featured" style={styles}>
      <div className="featured-content">
        <h1>{title}</h1>
        <h3>{t("featured.subtitle")}</h3>
      </div>
    </div>
  );
}

export default Featured;
