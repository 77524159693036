import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Nav.css";

function Nav() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
  };

  return (
    <div className="nav-container">
      <div className="hamburger" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`nav ${isOpen ? "open" : ""}`}>
        <li
          onClick={() => {
            navigate("/");
            closeMenu();
          }}
          className={location.pathname === "/" ? "active" : ""}
        >
          {t("nav.home")}
        </li>
        <li
          onClick={() => {
            navigate("/about");
            closeMenu();
          }}
          className={location.pathname === "/about" ? "active" : ""}
        >
          {t("nav.about")}
        </li>
        <li
          onClick={() => {
            navigate("/gallery");
            closeMenu();
          }}
          className={location.pathname === "/gallery" ? "active" : ""}
        >
          {t("nav.gallery")}
        </li>
        <li
          onClick={() => {
            navigate("/contact");
            closeMenu();
          }}
          className={location.pathname === "/contact" ? "active" : ""}
        >
          {t("nav.contact")}
        </li>
      </ul>

      <div className="language-switcher">
        {i18n.language === "en" && (
          <div className="flag-container" onClick={() => changeLanguage("es")}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/flag_icon_round_mx.png`}
              alt="Español"
              className="flag-icon"
            />
            <span className="language-name">Español</span>
          </div>
        )}
        {i18n.language === "es" && (
          <div className="flag-container" onClick={() => changeLanguage("en")}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/flag_icon_round_us.png`}
              alt="English"
              className="flag-icon"
            />
            <span className="language-name">English</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Nav;
