import React from "react";
import { useTranslation } from "react-i18next";
import Album from "../components/Album";
import "./Gallery.css";
import image1 from "../assets/images/img01.jpg";
import image2 from "../assets/images/img02.jpg";
import image3 from "../assets/images/img03.jpg";
import image4 from "../assets/images/img04.jpg";
import video from "../assets/videos/vid02.mp4";

const albumImages = [
  { src: image1, alt: "Image 1" },
  { src: image2, alt: "Image 2" },
  { src: image3, alt: "Image 3" },
  { src: image4, alt: "Image 4" },
];

const videos = [
  {
    src: video,
    title: "Video 1",
  },
];

function Gallery() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="section dark">
        <div className="spacing">
          <div className="cardboard dark">
            <video controls autoPlay width="100%">
              <source src={videos[0].src} type="video/mp4" />
              {t("gallery.videoNotSupported")}
            </video>
          </div>
        </div>
      </div>
      <div className="section light">
        <div className="spacing">
          <div className="cardboard dark">
            <p>
              {t("gallery.propertyDescription")}
            </p>
            <Album images={albumImages} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
