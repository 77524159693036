import React from "react";
import { useTranslation } from "react-i18next";

function Lot() {
  const { t } = useTranslation();

  return (
    <div>
      <h2>{t("lot.title")}</h2>
      <p>{t("lot.propertyDescription")}</p>
    </div>
  );
}

export default Lot;
