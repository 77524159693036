import React from "react";
import { useTranslation } from "react-i18next";

function Seller() {
  const { t } = useTranslation();

  return (
    <div className="seller">
      <h2>{t("seller.revealingProperty")}</h2>
      <p>Adam Diamond {t("seller.expertise")}</p>
      <p>{t("seller.inspectProperty")}</p>

      <h3>{t("seller.contactInformation")}</h3>
      <div className="seller-grid">
        <div className="seller-info">
          <p>{t("seller.usa")}</p>
          <ul>
            <li>
              <strong>Adam Diamond</strong>
              <br />
              <a href="tel:+19178078169">+1(917)807-8169</a>
              <br />
              <a href="mailto:adam@chartwellmanhattan.com">
                adam@chartwellmanhattan.com
              </a>
            </li>
          </ul>
        </div>
        <div className="seller-info">
          <p>{t("seller.mexico")}</p>
          <ul>
            <li>
              <strong>Marisol Guadalupe Palomera</strong>
              <br />
              <a href="tel:+523141746370">+52(314)174-6370</a>
              <br />
              <a href="mailto:solemarizol@hotmail.com">
                solemarizol@hotmail.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Seller;
